<template>
  <CreditPlatformProductLayout
    v-if="!objectIsEmpty(data)"
    :breadcrumb-items="[
      {
        text: 'Inicio',
        href: '/plataforma-credito',
      },
      {
        text: 'Detalhes Estabelecimento',
        href: `/plataforma-credito/estabelecimento/detalhes/${merchantId}`,
      },

      {
        text: 'Consulta',
      },
    ]"

    :merchant-selected-is-required="false"
    :optin-selected-is-required="false"
    :merchant="data.merchant"
  >
    <Page

      :schedule="data"
      :register="data.register"
    />
  </CreditPlatformProductLayout>
</template>

<script>
import ConsultService from '@/services/osiris/ConsultService';

import CercConsultService from '@/services/osiris/Cerc/ScheduleService';

import CercMapper from './mappers/CERC';
import TagMapper from './mappers/TAG';

import Page from './components/Page.vue';

import CreditPlatformProductLayout from '@/views/layouts/CreditPlatformItemProductLayout.vue';

const services = {
    CERC: CercConsultService,
    TAG: ConsultService,
};

const mappers = {
    CERC: CercMapper,
    // TAG: TagMapper,
};

export default {
    components: {
        Page,
        CreditPlatformProductLayout,
    },

    data() {
        return {
            id: this.$route.params.id,
            renderPage: false,
            merchantId: null,
            data: {},
        };
    },

    mounted() {
        this.getConsult();
    },

    methods: {
        async getConsult() {
            this.isLoading(true);
            this.renderPage = false;

            const requests = Object.values(services).map(service => service.getSchedule(this.id));

            let data = {};

            const res = await (Promise.all(requests)).then(response => response.find(item => item.status === 200));
            this.merchantId = res.data.merchant.id;
            data = res.data;

            this.data = new mappers[data.register]().map(data);

            // try {
            //     const res = await (Promise.all(requests)).then(response => response.find(item => item.status === 200));
            //     this.merchantId = res.data.merchant.id;
            //     data = res.data;

            //     this.data = new mappers[data.register]().map(data);
            // } catch {
            //     this.$router.push('/error-404');
            // }

            this.renderPage = true;
            this.isLoading(false);
        },
    },
};
</script>
